<template>
  <el-config-provider :locale="locale">
    <el-container>
      <el-header class="App_Header">
        <el-button
          class="hidden-md-and-up App_MenuIcon"
          plain
          :icon="Menu"
          @click="onClickToggleMenu"
        />
        <logo class="App_Logo" />
      </el-header>
      <el-container class="App_Conteiner">
        <el-aside width="200px" class="hidden-sm-and-down">
          <el-menu
            class="App_Menu"
            :router="true"
            :default-active="activeIndex"
          >
            <el-menu-item index="top" :route="{ path: '/' }">
              <template #title>ダッシュボード</template>
            </el-menu-item>
            <el-sub-menu index="analysis">
              <template #title>データ</template>
              <el-menu-item
                index="analysis.sales"
                :route="{ name: 'analysis.sales' }"
              >
                <template #title>期間集計</template>
              </el-menu-item>
            </el-sub-menu>
            <el-menu-item
              index="consults.index"
              :route="{ name: 'consults.index' }"
            >
              <template #title>相談</template>
            </el-menu-item>
            <el-sub-menu index="customers">
              <template #title>顧客</template>
              <el-menu-item
                index="customers.index"
                :route="{ name: 'customers.index' }"
              >
                <template #title>一覧</template>
              </el-menu-item>
              <el-menu-item
                index="customers.introducer"
                :route="{ name: 'customers.introducer' }"
              >
                <template #title>紹介元一覧</template>
              </el-menu-item>
            </el-sub-menu>
            <el-sub-menu index="orders">
              <template #title>受注</template>
              <el-menu-item
                index="orders.index"
                :route="{ name: 'orders.index' }"
              >
                <template #title>一覧</template>
              </el-menu-item>
              <el-menu-item
                index="orders.kanban"
                :route="{ name: 'orders.kanban' }"
              >
                <template #title>カンバン</template>
              </el-menu-item>
              <el-menu-item
                index="orders.products.index"
                :route="{ name: 'orders.products.index' }"
              >
                <template #title>製品一覧</template>
              </el-menu-item>
            </el-sub-menu>
            <el-sub-menu index="products">
              <template #title>商品</template>
              <el-menu-item
                index="products.index"
                :route="{ name: 'products.index' }"
              >
                <template #title>一覧</template>
              </el-menu-item>
              <el-menu-item
                index="products.inventory"
                :route="{ name: 'products.inventory' }"
              >
                <template #title>在庫</template>
              </el-menu-item>
              <el-menu-item
                index="products.stock"
                :route="{ name: 'products.stock' }"
              >
                <template #title>仕入管理</template>
              </el-menu-item>
            </el-sub-menu>
            <el-menu-item
              index="offices.index"
              :route="{ name: 'offices.index' }"
            >
              <template #title>施設・病院・事務所</template>
            </el-menu-item>
            <el-menu-item
              index="administrator.index"
              :route="{ name: 'offices.index', query: { office_type: 1 } }"
            >
              <template #title>行政</template>
            </el-menu-item>
            <el-menu-item
              index="suppliers.index"
              :route="{ name: 'offices.index', query: { office_type: 20 } }"
            >
              <template #title>取引先</template>
            </el-menu-item>
            <el-sub-menu v-if="role >= 3" index="payments">
              <template #title> 入金管理 </template>
              <el-menu-item
                index="payments.admin"
                :route="{ name: 'payments.admin' }"
              >
                <template #title>行政振込管理</template>
              </el-menu-item>
              <el-menu-item
                index="payments.copayment"
                :route="{ name: 'payments.copayment' }"
              >
                <template #title>集金管理</template>
              </el-menu-item>
            </el-sub-menu>
            <el-sub-menu index="masters">
              <template #title> マスター </template>
              <el-menu-item
                index="masters.category"
                :route="{ name: 'masters.category' }"
              >
                <template #title>カテゴリ</template>
              </el-menu-item>
              <el-menu-item
                index="masters.index"
                :route="{ name: 'masters.index' }"
              >
                <template #title>アイテム</template>
              </el-menu-item>
            </el-sub-menu>
          </el-menu>
        </el-aside>
        <el-main><router-view /></el-main>
      </el-container>
    </el-container>
    <el-drawer v-model="openDrawer" size="80%">
      <el-menu class="App_Menu" :router="true" :default-active="activeIndex">
        <el-menu-item
          index="top"
          :route="{ path: '/' }"
          @click="openDrawer = false"
        >
          <template #title>ダッシュボード</template>
        </el-menu-item>
        <el-sub-menu index="analysis">
          <template #title>データ</template>
          <el-menu-item
            index="analysis.sales"
            :route="{ name: 'analysis.sales' }"
            @click="openDrawer = false"
          >
            <template #title>期間集計</template>
          </el-menu-item>
        </el-sub-menu>
        <el-menu-item
          index="consults.index"
          :route="{ name: 'consults.index' }"
          @click="openDrawer = false"
        >
          <template #title>相談</template>
        </el-menu-item>
        <el-sub-menu index="customers">
          <template #title>顧客</template>
          <el-menu-item
            index="customers.index"
            :route="{ name: 'customers.index' }"
            @click="openDrawer = false"
          >
            <template #title>一覧</template>
          </el-menu-item>
          <el-menu-item
            index="customers.introducer"
            :route="{ name: 'customers.introducer' }"
            @click="openDrawer = false"
          >
            <template #title>紹介元一覧</template>
          </el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="orders">
          <template #title>受注</template>
          <el-menu-item
            index="orders.index"
            :route="{ name: 'orders.index' }"
            @click="openDrawer = false"
          >
            <template #title>一覧</template>
          </el-menu-item>
          <el-menu-item
            index="orders.kanban"
            :route="{ name: 'orders.kanban' }"
            @click="openDrawer = false"
          >
            <template #title>カンバン</template>
          </el-menu-item>
          <el-menu-item
            index="orders.products.index"
            :route="{ name: 'orders.products.index' }"
            @click="openDrawer = false"
          >
            <template #title>製品一覧</template>
          </el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="products">
          <template #title>商品</template>
          <el-menu-item
            index="products.index"
            :route="{ name: 'products.index' }"
            @click="openDrawer = false"
          >
            <template #title>一覧</template>
          </el-menu-item>
          <el-menu-item
            index="products.inventory"
            :route="{ name: 'products.inventory' }"
            @click="openDrawer = false"
          >
            <template #title>在庫</template>
          </el-menu-item>
          <el-menu-item
            index="products.stock"
            :route="{ name: 'products.stock' }"
            @click="openDrawer = false"
          >
            <template #title>仕入管理</template>
          </el-menu-item>
        </el-sub-menu>
      </el-menu>
    </el-drawer>
  </el-config-provider>
</template>

<script setup>
import ja from 'element-plus/lib/locale/lang/ja'
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import Logo from '~/components/atoms/Logo.vue'
import { apiClient } from './services/APIService'
import { useErrorStore } from '~/store/errorStore'
import { ElNotification } from 'element-plus'
import { Menu } from '@element-plus/icons-vue'

const route = useRoute()
const role = ref(2)
const errorStore = useErrorStore()
const errorMessage = computed(() => errorStore.message)

onMounted(async () => {
  const { data } = await apiClient.get('/user')
  role.value = data.role
})

watch(
  () => errorMessage.value,
  (newMessage, oldMessage) => {
    if (newMessage && newMessage !== oldMessage) {
      ElNotification({
        title: 'エラー',
        message: newMessage,
        type: 'error',
        duration: 0,
      })
      errorStore.clearMessage()
    }
  }
)

const activeIndex = computed(() => {
  if (route.name === 'offices.index' && +route.query.office_type === 20) {
    return 'suppliers.index'
  }
  if (route.name === 'offices.index' && +route.query.office_type === 1) {
    return 'administrators.index'
  }
  return route.name
})

const locale = ja

const openDrawer = ref(false)

const onClickToggleMenu = () => {
  openDrawer.value = true
}
</script>

<style lang="scss" scoped>
.App {
  &_Header {
    display: flex;
    align-items: center;
    background-color: #dcdfe6;
  }

  &_Logo {
    height: 60%;
  }

  &_Conteiner {
    min-height: calc(100vh - 60px);
  }

  &_Menu {
    height: 100%;
  }

  &_MenuIcon {
    margin-right: 16px;
  }
}
</style>
