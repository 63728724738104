<template>
  <svg viewBox="0 0 404 61">
    <path
      class="fill1 hidden-sm-and-down"
      fill="#E8390D"
      d="M80.036,45.119h-5.883v-17.65h5.883V45.119z M80.036,23.058h-5.883v-5.883h5.883V23.058z"
    ></path>
    <polygon
      class="fill2 hidden-sm-and-down"
      points="115.332,23.058 103.567,23.058 103.567,45.119 97.685,45.119 97.685,23.058 85.917,23.058 85.917,17.175
			115.332,17.175 "
    ></polygon>
    <polygon
      class="fill2 hidden-sm-and-down"
      points="127.097,33.353 127.097,39.236 150.629,39.236 150.629,45.119 121.215,45.119 121.215,17.176 150.629,17.176
			150.629,23.058 127.097,23.058 127.097,27.47 150.629,27.47 150.629,33.353 "
    ></polygon>
    <polygon
      class="fill2 hidden-sm-and-down"
      points="162.394,23.058 162.394,39.236 185.924,39.236 185.924,45.119 156.511,45.119 156.511,17.175 185.924,17.175
			185.924,23.058 "
    ></polygon>
    <polygon
      class="fill3 hidden-sm-and-down"
      fill="#727172"
      points="221.222,27.47 221.222,45.119 191.808,45.119 191.808,39.236 215.339,39.236 215.339,33.353
			191.808,33.353 191.808,17.175 221.222,17.175 221.222,23.058 197.69,23.058 197.69,27.47 "
    ></polygon>
    <polygon
      class="fill3 hidden-sm-and-down"
      fill="#727172"
      points="256.518,33.353 244.752,33.353 244.752,45.119 238.871,45.119 238.871,33.353 227.104,33.353
			227.104,17.176 232.988,17.176 232.988,27.47 250.636,27.47 250.636,17.176 256.518,17.176 "
    ></polygon>
    <polygon
      class="fill3 hidden-sm-and-down"
      fill="#727172"
      points="291.815,27.47 291.815,45.119 262.401,45.119 262.401,39.236 285.932,39.236 285.932,33.353
			262.401,33.353 262.401,17.175 291.815,17.175 291.815,23.058 268.284,23.058 268.284,27.47 "
    ></polygon>
    <polygon
      class="fill3 hidden-sm-and-down"
      fill="#727172"
      points="327.111,23.058 315.346,23.058 315.346,45.119 309.464,45.119 309.464,23.058 297.697,23.058
			297.697,17.175 327.111,17.175 "
    ></polygon>
    <polygon
      class="fill3 hidden-sm-and-down"
      fill="#727172"
      points="338.876,33.353 338.876,39.236 362.409,39.236 362.409,45.119 332.995,45.119 332.995,17.176
			362.409,17.176 362.409,23.058 338.876,23.058 338.876,27.47 362.409,27.47 362.409,33.353 "
    ></polygon>
    <polygon
      class="fill3 hidden-sm-and-down"
      fill="#727172"
      points="397.705,45.119 397.705,29.456 388.88,45.119 382.999,45.119 374.174,29.456 374.174,45.119
			368.291,45.119 368.291,17.175 374.174,17.175 385.939,38.794 397.705,17.175 403.587,17.175 403.587,45.119 "
    ></polygon>
    <rect class="fill1" fill="#E8390D" width="61.116" height="61.116"></rect>
    <rect
      class="fill4"
      x="8.516"
      y="12.227"
      fill="#FFFFFF"
      width="11.42"
      height="11.422"
    ></rect>
    <rect
      class="fill4"
      x="8.516"
      y="27.864"
      fill="#FFFFFF"
      width="11.42"
      height="20.472"
    ></rect>
    <polygon
      class="fill4"
      fill="#FFFFFF"
      points="54.939,23.646 45.363,23.646 45.363,48.335 33.944,48.335 33.944,23.646 24.438,23.646
			24.438,12.227 54.939,12.227 "
    ></polygon>
  </svg>
</template>

<style lang="scss" scoped>
svg * {
  stroke: #999;
  fill: none;
  stroke-width: 1;
  stroke-dasharray: 5000;
  stroke-dashoffset: 5000;
}

@keyframes DASH {
  0% {
    stroke-dashoffset: 5000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

.fill1 {
  animation: DASH 10s ease-in-out alternate forwards,
    fill1 0.5s ease-in 1.85s alternate forwards;
}
.fill2 {
  animation: DASH 10s ease-in-out alternate forwards,
    fill2 0.5s ease-in 1.85s alternate forwards;
}
.fill3 {
  animation: DASH 10s ease-in-out alternate forwards,
    fill3 0.5s ease-in 1.85s alternate forwards;
}
.fill4 {
  animation: DASH 10s ease-in-out alternate forwards,
    fill4 0.5s ease-in 1.85s alternate forwards;
}

@keyframes fill1 {
  0% {
    fill: rgba(232, 57, 13, 0);
    stroke-width: 1;
  }
  100% {
    fill: rgba(232, 57, 13, 1);
    stroke-width: 0;
  }
}
@keyframes fill2 {
  0% {
    fill: rgba(0, 0, 0, 0);
    stroke-width: 1;
  }
  100% {
    fill: rgba(0, 0, 0, 1);
    stroke-width: 0;
  }
}
@keyframes fill3 {
  0% {
    fill: rgba(114, 113, 114, 0);
    stroke-width: 1;
  }
  100% {
    fill: rgba(114, 113, 114, 1);
    stroke-width: 0;
  }
}
@keyframes fill4 {
  0% {
    fill: rgba(255, 255, 255, 0);
    stroke-width: 1;
  }
  100% {
    fill: rgba(255, 255, 255, 1);
    stroke-width: 0;
  }
}
</style>
