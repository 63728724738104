require('./bootstrap')

// import './theme/index.css'
import '../css/app.css'
import '../css/app.scss'
import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import router from './router'
import App from './App.vue'
import locale from 'element-plus/lib/locale/lang/ja'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import { createPinia } from 'pinia'

const app = createApp(App)

app.use(router)
app.use(ElementPlus, { locale })
app.config.globalProperties.$dayjs = dayjs
app.provide('$dayjs', dayjs)

app.use(createPinia())

app.mount('#app')
