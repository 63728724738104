import axios from 'axios'
import router from '~/router'
import { useCookie } from '@vue-composable/cookie'
import { useErrorStore } from '~/store/errorStore'

export const apiClient = axios.create({
  baseURL: `${process.env.MIX_APP_URL}/api`,
  withCredentials: true
})

apiClient.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const errorStore = useErrorStore()

    if (error.response) {
      if ([401, 419].includes(error.response.status)) {
        // logout
        const { cookie } = useCookie('redirect')
        cookie.value = location.pathname !== '/login' ? location.pathname : cookie.value

        router.replace('/login')
        return Promise.resolve()
      }
      if (error.response.status === 403) {
        router.replace('/')
        return Promise.reject(error)
      }
    }
    if (error.response.data.message) {
      errorStore.setMessage(error.response.data.message)
    }

    return Promise.reject(error)
  }
)

export default {
  async get(api, payload) {
    const ret = await apiClient.get(api, payload)
    return ret
  },

  async post(api, payload) {
    return await apiClient.post(api, payload)
  },

  async put(api, payload) {
    return await apiClient.put(api, payload)
  },

  async patch(api, payload) {
    return await apiClient.patch(api, payload)
  },

  async delete(api, payload) {
    return await apiClient.delete(api, payload)
  }
}
