import { defineStore } from "pinia"

export const useErrorStore = defineStore('error', {
  state: () => ({
    message: '',
  }),

  actions: {
    setMessage(message) {
      this.message = message
    },

    clearMessage() {
      this.message = ''
    }
  }
})
