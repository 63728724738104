import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('~/views/Index/Index.vue'),
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('~/views/Login/Index.vue'),
    },
    {
      path: '/products',
      name: 'products.index',
      component: () => import('~/views/Products/Index.vue'),
    },
    {
      path: '/products/create',
      name: 'products.create',
      component: () => import('~/views/Products/Create.vue'),
    },
    {
      path: '/products/:id/edit',
      name: 'products.edit',
      component: () => import('~/views/Products/_id/Edit.vue'),
    },
    {
      path: '/products/:id/options',
      name: 'products.options',
      component: () => import('~/views/Products/_id/Option/Index.vue'),
    },
    {
      path: '/products/:id/options/create',
      name: 'products.options.create',
      component: () => import('~/views/Products/_id/Option/Create.vue'),
    },
    {
      path: '/products/:id/options/:option_id/edit',
      name: 'products.options.edit',
      component: () => import('~/views/Products/_id/Option/_id/Edit.vue'),
    },
    {
      path: '/products/inventory',
      name: 'products.inventory',
      component: () => import('~/views/Products/Inventory.vue'),
    },
    {
      path: '/products/stock',
      name: 'products.stock',
      component: () => import('~/views/Products/Stock.vue'),
    },
    {
      path: '/offices',
      name: 'offices.index',
      component: () => import('~/views/Offices/Index.vue'),
    },
    {
      path: '/offices/create',
      name: 'offices.create',
      component: () => import('~/views/Offices/Create.vue'),
    },
    {
      path: '/offices/:id/edit',
      name: 'offices.edit',
      component: () => import('~/views/Offices/_id/Edit.vue'),
    },
    {
      path: '/offices/:id/payments',
      name: 'offices.payments',
      component: () => import('~/views/Offices/_id/Payment.vue'),
    },
    {
      path: '/offices/:id/orders',
      name: 'offices.orders',
      component: () => import('~/views/Offices/_id/Order.vue'),
    },
    {
      path: '/masters',
      name: 'masters.index',
      component: () => import('~/views/Masters/Index.vue'),
    },
    {
      path: '/masters/category',
      name: 'masters.category',
      component: () => import('~/views/Masters/Category.vue'),
    },
    {
      path: '/orders',
      name: 'orders.index',
      component: () => import('~/views/Orders/Index.vue'),
    },
    {
      path: '/orders/kanban',
      name: 'orders.kanban',
      component: () => import('~/views/Orders/Kanban.vue'),
    },
    {
      path: '/orders/create',
      name: 'orders.create',
      component: () => import('~/views/Orders/Create.vue'),
    },
    {
      path: '/orders/:id/edit',
      name: 'orders.edit',
      component: () => import('~/views/Orders/_id/Edit.vue'),
    },
    {
      path: '/orders/products/index',
      name: 'orders.products.index',
      component: () => import('~/views/Orders/Products/Index.vue'),
    },
    {
      path: '/customers',
      name: 'customers.index',
      component: () => import('~/views/Customers/Index.vue'),
    },
    {
      path: '/customers/create',
      name: 'customers.create',
      component: () => import('~/views/Customers/Create.vue'),
    },
    {
      path: '/customers/:id/edit',
      name: 'customers.edit',
      component: () => import('~/views/Customers/_id/Edit.vue'),
    },
    {
      path: '/customers/:id/history',
      name: 'customers.history',
      component: () => import('~/views/Customers/_id/History.vue'),
    },
    {
      path: '/customers/introducer',
      name: 'customers.introducer',
      component: () => import('~/views/Customers/Introducer.vue'),
    },
    {
      path: '/customers/:id/families',
      name: 'customers.families',
      component: () => import('~/views/Customers/_id/Family.vue'),
    },
    {
      path: '/consults',
      name: 'consults.index',
      component: () => import('~/views/Consults/Index.vue'),
    },
    {
      path: '/consults/create',
      name: 'consults.create',
      component: () => import('~/views/Consults/Create.vue'),
    },
    {
      path: '/consults/:id/edit',
      name: 'consults.edit',
      component: () => import('~/views/Consults/_id/Edit.vue'),
    },
    {
      path: '/payments/admin',
      name: 'payments.admin',
      component: () => import('~/views/Payments/Admin.vue'),
    },
    {
      path: '/payments/copayment',
      name: 'payments.copayment',
      component: () => import('~/views/Payments/Copayment.vue'),
    },
    {
      path: '/analysis/sales',
      name: 'analysis.sales',
      component: () => import('~/views/Analysis/Period.vue'),
    },
  ],
})

export default router
